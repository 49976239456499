/* ========== AI VIEW TOOL STYLES ========== */
.email-view-tool {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }
  
  .email-view-tool__content {
    width: 50%;
    height: 80%;
    border-radius: 12px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #212429;
    color: #ffffff;
  }
  
  .email-view-tool__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background-color: #212429;
    color: #ffffff;
  }

  .email-view-tool__email-body {
    background-color: #383838;
    padding: 50px 30px;
  }
  
  .email-view-tool__title {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .email-view-tool__icon {
    width: 24px;
    height: 24px;
  }
  
  .email-view-tool__title h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
  
  .email-view-tool__request-btn {
    background-color: #3b4da1;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 10px 16px;
    font-weight: 600;
    cursor: pointer;
    margin-left: 50%;
  }
  
  .email-view-tool__request-btn:hover {
    background-color: #2e3c7d;
  }
  
  .email-view-tool__close-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #666;
    margin-left: 20px;
  }
  
  .email-view-tool__close-btn:hover {
    color: #333;
  }

  .email-view-tool__email-container {
    padding: 0 24px 24px;
  }
  
  .email-view-tool__field {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    background-color: #383838;
  }
  
  .email-view-tool__field label {
    width: 90px;
    font-weight: 500;
    font-size: 16px;
  }
  
  .email-view-tool__field input {
    flex: 1;
    padding: 10px;
    border-radius: 4px;
    border: none;
    background-color: #2e3139;
    color: white;
    font-size: 16px;
  }
  
  .email-view-tool__body-container {
    margin-bottom: 20px;
    background-color: #383838;
  }
  
  .email-view-tool__body {
    width: 98%;
    padding: 10px;
    border-radius: 4px;
    border: none;
    background-color: #2e3139;
    color: white;
    font-family: inherit;
    font-size: 16px;
    resize: vertical;
    min-height: 150px;
  }
  
  .email-view-tool__actions {
    display: flex;
    gap: 10px;
    justify-content: right;
  }
  
  .email-view-tool__action-btn {
    padding: 8px 16px;
    border-radius: 4px;
    border: none;
    font-weight: 500;
    cursor: pointer;
    background-color: #4752a5;
    color: white;
    margin-right: 10px;
  }