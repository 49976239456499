/* ========== GENERAL PROOF COMPONENT STYLES ========== */
.proof {
  display: flex;
  flex-direction: column;
}

.proof__description {
  margin: 0;
  margin-bottom: 5px;
}

.proof__description--intro {
  margin-bottom: 10px;
  margin-top: 20px;
}

/* ========== LIST SECTION STYLES ========== */
.proof__verification-steps-list,
.proof__document-types-list {
  margin: 0;
  margin-bottom: 5px;
}

.proof__verification-step-item,
.proof__document-types-item {
  margin-bottom: 5px;
}

/* ========== CLARIFICATION SECTION STYLES ========== */
.proof__clarification {
  margin-top: 15px;
}

/* ========== UPLOAD AREA STYLES ========== */
.proof__upload-section {
  margin-top: 40px;
}

.proof__description--upload-area {
  font-size: 17px;
  margin-bottom: 10px;
}

/* ========== FILES LIST STYLES ========== */
.proof__files-analysis {
  margin-top: 25px;
}

.proof__files {
  width: 750px;
  border-top: 1px solid black;
  padding: 0 12px;
}

.proof__file-item {
  color: black;
  font-size: 16px;
  border-bottom: 0.5px solid #afafaf;
}

.proof__file-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.proof__file-toggle {
  cursor: pointer;
}

.proof__file-details {
  font-size: 14px;
  font-weight: 400;
  display: flex;
}

.proof__file-details-title {
  padding: 0 50px;
}

.proof__file-type {
  color: #cfcfcf;
}

.proof__file-details-title,
.proof__file-type {
  margin: 0;
  margin-bottom: 20px;
}

.proof__loading-bar-container {
  width: 275px;
  height: 10px;
  background-color: #dfdfdf;
  overflow: hidden;
  position: relative;
}

.proof__loading-bar {
  width: 100%;
  height: 100%;
  background-color: #2aa917;
  animation: loading 3s linear;
}

.proof__loading-bar.complete {
  background-color: #2aa917;
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.proof__file-delete {
  cursor: pointer;
}

.proof__file-delete img {
  display: block;
}

/* ========== CTA BUTTON STYLES ========== */
.proof__cta {
  margin-top: 40px;
}

.proof___cta-button {
  background-color: var(--dashboard-primary-color);
  cursor: pointer;
  color: white;
  border: none;
  font-size: 14px;
  padding: 12px 11px;
  border-radius: 10px;
}

.proof___cta-button:disabled {
  background-color: #cbcbcc;
  cursor: not-allowed;
}
.proof___cta-button:not(:disabled):hover {
  opacity: 0.8;
}
