.disclosure-package {
  display: flex;
  margin-top: 20px;
}

.disclosure-package__uploading {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.disclosure-package__review-btn:hover,
.disclosure-package__continue-btn:hover,
.disclosure-package__upload-btn:hover {
  opacity: 0.8;
}

.disclosure-package__skip {
  color: #999;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}

.disclosure-package__skip:hover {
  opacity: 0.8;
}

.disclosure-package__checkmark-image {
  width: 20px;
}
.disclosure-package__description,
.disclosure-package__search-status,
.disclosure-package__message {
  font-size: 23px;
  font-weight: medium;
  color: var(--dashboard-primary-color);
  margin-bottom: 20px;
}

.disclosure-package__loading {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.disclosure-package__spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(67, 97, 238, 0.2);
  border-top: 3px solid var(--dashboard-primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.disclosure-package__status {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.disclosure-package__status-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: #999;
  margin-right: 8px;
}

.disclosure-package__status-text {
  color: #999;
  font-size: 16px;
}

.disclosure-package__actions {
  display: flex;
  margin-top: 30px;
  gap: 30px;
}

.disclosure-package__review-btn,
.disclosure-package__continue-btn,
.disclosure-package__upload-btn {
  width: auto;
  background-color: var(--dashboard-primary-color);
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 11px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.disclosure-package__upload-icon {
  margin-right: 8px;
}

.disclosure-package__uploading {
  margin-top: 5px;
}

.disclosure-package__review-btn:hover,
.disclosure-package__continue-btn:hover,
.disclosure-package__upload-btn:hover {
  opacity: 0.8;
}

.disclosure-package__skip {
  color: #999;
  text-decoration: underline;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}

.disclosure-package__skip:hover {
  opacity: 0.8;
}

.disclosure-package__checkmark-image {
  width: 20px;
}

.disclosure-package__analysis-results {
  width: 100%;
}

.disclosure-package__analysis-item {
  font-size: 14px;
}
