/* ========== GENERAL PRE-APPROVAL COMPONENT STYLES ========== */
.pre-approval {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.pre-approval__title {
  margin: 0;
  margin-bottom: 15px;
}

/* ========== UPLOAD SECTION STYLES ========== */
.pre-approval__upload-section {
  margin-bottom: 40px;
}

/* ========== CTA BUTTON STYLES ========== */
.pre-approval__cta {
  margin-top: 60px;
}

.pre-approval__cta-button {
  background-color: var(--dashboard-primary-color);
  cursor: pointer;
  color: white;
  border: none;
  font-size: 14px;
  padding: 12px 11px;
  border-radius: 10px;
}

.pre-approval__cta-button:disabled {
  background-color: #cbcbcc;
  cursor: not-allowed;
}
.pre-approval__cta-button:not(:disabled):hover {
  opacity: 0.8;
}

/* ========== ENTER CASH STYLES ========== */
.pre-approval__enter-cash {
  margin-top: 10px;
}

.pre-approval__enter-cash-text {
  color: #cbcbcc;
  margin: 0;
  text-decoration: underline;
  font-size: 11px;
  cursor: pointer;
}
