.comp-results {
  width: 100%;
  margin-left: 2%;
  display: flex;
}

.comp-results-content {
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #24293f;
  color: #ffffff;
  overflow: scroll;
}

.comp-results-content table {
  border-collapse: collapse;
  width: 100%;
  margin: 1rem 0;
}

.comp-results-content th,
.comp-results-content td {
  border: 1px solid #e0e0e0;
  padding: 8px 12px;
  text-align: left;
  font-weight: normal;
}

.comp-results-content th {
  background-color: #24293f;
  color: white; /* Add text color for better visibility */
  font-weight: bolder;
}

.comp-results-content tr:nth-child(even) {
  background-color: #24293f;
  color: white; /* Add text color for better visibility */
}

.comp-results-content h2,
.comp-results-content h3,
.comp-results-content h4 {
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.comp-results-content p {
  margin-bottom: 1rem;
}

/* Dropdown styling to match AnalysisResults */
.comp-results__dropdown-button {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 10px 15px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px; /* Add border radius to match */
}

.comp-results__dropdown-button--open {
  background-color: #e0e0e0;
}

.comp-results__dropdown-arrow {
  font-size: 1.2em;
}

.comp-results__dropdown-content {
  border: 1px solid #ddd;
  border-top: none;
  padding: 10px 15px;
}
