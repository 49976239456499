.comparable-homes {
  display: flex;
  margin-top: 20px;
}

.comparable-homes__description,
.comparable-homes__search-status,
.comparable-homes__message {
  font-size: 24px;
  font-weight: medium;
  color: var(--dashboard-primary-color);
  margin-bottom: 20px;
}

.comparable-homes__loading {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.comparable-homes__spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(67, 97, 238, 0.2);
  border-top: 3px solid var(--dashboard-primary-color);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.comparable-homes__status {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.comparable-homes__status-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: #999;
  margin-right: 8px;
}

.comparable-homes__status-text {
  color: #999;
  font-size: 16px;
}

.comparable-homes__actions {
    display: flex;
    gap: 30px;
}

.comparable-homes__view-ai-button {
  width: auto;
  background-color: var(--dashboard-primary-color);
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 11px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.comparable-homes__view-ai-button:hover {
  opacity: 0.8;
}

.comparable-homes__map-image {
  position: absolute;
  top: 25%;
  right: 10%;
  height: 400px;
}
