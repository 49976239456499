/* ========== UPLOAD COMPONENT STYLES ========== */
.file-upload {
  margin-bottom: 40px;
}

.file-upload-area {
  border: 2px dashed var(--dashboard-primary-color);
  border-radius: 5px;
  text-align: center;
  background-color: #f8f9ff;
  transition: all 0.3s ease;
  height: 110px;
  width: 700px;
  cursor: pointer;
}

.file-upload__text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.file-upload__text {
  margin-bottom: 0;
}

.file__upload-text--light {
  font-weight: 200;
}

.file__upload-text--medium {
  font-weight: 400;
}

.file__upload-formats {
  margin: 0;
  font-size: 8px;
}

/* ========== PRE-APPROVAL METADATA COMPONENT STYLES ========== */
.pre-approval-meta-data {
  width: 750px;
  padding: 0 5px;
}

.pre-approval-meta-data--shown {
  border-top: 1px solid black;
}

.pre-approval-meta-data__container {
  color: black;
  font-size: 16px;
  border-bottom: 0.5px solid #afafaf;
}

.pre-approval-meta-data__header {
  display: flex;
  align-items: center;
}

.pre-approval-meta-data__toggle,
.pre-approval-meta-data__delete {
  cursor: pointer;
}

.pre-approval-meta-data__toggle {
  margin-left: 15px;
}

.pre-approval-meta-data__file-name {
  margin-left: 15px;
}

.pre-approval-meta-data__delete {
  margin-left: auto;
}

.pre-approval-meta-data__data-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.pre-approval-meta-data__info {
  display: flex;
  margin-bottom: 5px;
}

.pre-approval-meta-data__info-key,
.pre-approval-meta-data__info-value {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.pre-approval-meta-data__info-key {
  width: 145px;
  padding: 0 20px;
}


/* ========== PROOF OF FUNDS METADATA COMPONENT STYLES ========== */
.proof-of-funds-meta-data {
  width: 750px;
  padding: 0 5px;
}

.proof-of-funds-meta-data--shown {
  border-top: 1px solid black;
}

.proof-of-funds-meta-data__container {
  color: black;
  font-size: 16px;
  border-bottom: 0.5px solid #afafaf;
}

.proof-of-funds-meta-data__header {
  display: flex;
  align-items: center;
}

.proof-of-funds-meta-data__toggle,
.proof-of-funds-meta-data__delete {
  cursor: pointer;
}

.proof-of-funds-meta-data__toggle {
  margin-left: 15px;
}

.proof-of-funds-meta-data__file-name {
  margin-left: 15px;
}

.proof-of-funds-meta-data__delete {
  margin-left: auto;
}

.proof-of-funds-meta-data__data-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.proof-of-funds-meta-data__info {
  display: flex;
  margin-bottom: 5px;
}

.proof-of-funds-meta-data__info-key,
.proof-of-funds-meta-data__info-value {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.proof-of-funds-meta-data__info-key {
  width: 145px;
  padding: 0 20px;
}

.proof-of-funds-meta-data__info-value {
  color: #CFCFCF;
}