/* ========== OFFER WRITING COMPONENT STYLES ========== */
.offer-writing {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.offer-writing__description {
  margin: 0;
  margin-bottom: 20px;
  font-size: 20px;
}
