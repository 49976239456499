.dashboard {
  background-color: #f5f5f5;
  color: #333;
  line-height: 1.5;
  box-sizing: border-box;
}

/* Main content layout */

.dashboard-main-content {
  display: flex;
  padding: 0;
  flex: 1;
}

.dashboard-left-panel {
  flex: 0 0 35%;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  background-color: white;
}

.dashboard-right-panel {
  flex: 0 0 65%;
  padding: 20px;
  background-color: #f5f5f5;
  overflow-y: auto;
  box-sizing: border-box;
}

/* Property card styles */

.dashboard-property-card {
  width: 100%;
  background-color: white;
}

.dashboard-date-banner {
  padding: 16px 24px;
  border-bottom: 1px solid #eee;
}

.dashboard-date-banner p {
  margin: 0;
  padding: 0;
  color: #666;
  font-size: 14px;
}

.dashboard-date-banner h2 {
  margin: 0;
  padding: 0;

  font-size: 22px;
  margin-top: 4px;
  font-weight: 500;
}

.dashboard-property-image-container {
  position: relative;
  width: 100%;
  height: 240px;
  overflow: hidden;
}

.dashboard-property-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard-arrow-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  z-index: 2;
}

.dashboard-arrow-btn.left {
  left: 12px;
}

.dashboard-arrow-btn.right {
  right: 12px;
}

.dashboard-property-details {
  padding: 16px 24px;
  border-bottom: 1px solid #eee;
}

.dashboard-address-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 6px;
}

.dashboard-address-info h3 {
  margin: 0;
  padding: 0;

  font-size: 18px;
  font-weight: 500;
}

.dashboard-expand-btn {
  margin: 0;
  padding: 0;

  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  color: #666;
}

.dashboard-property-specs {
  color: #666;
  font-size: 14px;
}

/* Tab panel styles */

.dashboard-tab-panel {
  background-color: white;
}

.dashboard-tab-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 0 24px;
}

.dashboard-tab-btn {
  padding: 16px 0;
  margin-right: 16px;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  position: relative;
}

.dashboard-tab-btn.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #0e6bfc;
}

.dashboard-update-count {
  font-size: 14px;
  color: #666;
  margin-left: auto;
  margin-right: 12px;
}

.dashboard-sound-btn {
  margin: 0;
  padding: 0;

  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #666;
}

/* Feed content styles */

.dashboard-feed-content {
  padding: 16px 24px;
}

/* Activity item styles */

.dashboard-activity-item {
  display: flex;
  padding: 12px 0;
  border-bottom: 1px solid #eee;
}

.dashboard-activity-thumbnail {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 4px;
  margin-right: 16px;
}

.dashboard-dashboard-activity-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard-activity-details {
  flex: 1;
}

.dashboard-activity-details h4 {
  margin: 0;
  padding: 0;

  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.dashboard-activity-address,
.dashboard-activity-from,
.dashboard-activity-time {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #666;
  margin-bottom: 2px;
}

.dashboard-activity-action {
  display: flex;
  align-items: center;
}

.dashboard-action-btn {
  background-color: transparent;
  border: 1px solid #0e6bfc;
  color: #0e6bfc;
  padding: 4px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.dashboard-price-change {
  color: #f44336;
  font-size: 14px;
  font-weight: 500;
}

/* Contacts section styles */

.dashboard-contacts-section {
  margin-top: 24px;
  border-top: 1px solid #eee;
  padding-top: 24px;
}

.dashboard-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.dashboard-section-header h3 {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
}

.dashboard-email-all-btn {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  color: #0e6bfc;
  font-size: 12px;
  cursor: pointer;
}

.dashboard-team-label {
  font-size: 12px;
  color: #666;
  margin-bottom: 12px;
}

/* Contact item styles */

.dashboard-contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.dashboard-contact-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
}

.dashboard-contact-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.dashboard-contact-info {
  flex: 1;
}

.dashboard-contact-info h4 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.dashboard-contact-role,
.dashboard-contact-email,
.dashboard-contact-phone {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #666;
  margin-bottom: 2px;
}

.dashboard-call-btn {
  margin: 0;
  padding: 0;
  background-color: #0e6bfc;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 4px 16px;
  font-size: 12px;
  cursor: pointer;
}

/* Vendors section styles */

.dashboard-vendors-section {
  margin: 0;
  padding: 0;
  margin-top: 24px;
  padding: 24px 0;
  display: flex;
  justify-content: center;
}

.dashboard-explore-vendors-btn {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  color: #0e6bfc;
  font-size: 14px;
  cursor: pointer;
}

/* Upcoming events styles */

.dashboard-upcoming-events {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.dashboard-upcoming-events h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

/* Event item styles */

.dashboard-event-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  position: relative;
  box-sizing: border-box;
}

.dashboard-event-marker {
  margin: 0;
  padding: 0;
  width: 4px;
  height: 100%;
  background-color: #0e6bfc;
  border-radius: 2px;
  margin-right: 12px;
  flex-shrink: 0;
}

.dashboard-event-details {
  flex: 1;
}

.dashboard-event-details h4 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
}

.dashboard-event-date {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #666;
}

.dashboard-event-action-btn {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  color: #0e6bfc;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

/* Home progress styles */

.dashboard-home-progress {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dashboard-home-progress h3 {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
}

/* Progress timeline styles */

.dashboard-progress-timeline {
  position: relative;
}

.dashboard-progress-timeline::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 8px;
  width: 2px;
  height: calc(100% - 16px);
  background-color: #ddd;
  z-index: 0;
}

/* Progress item styles */

.dashboard-progress-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
}

.dashboard-progress-marker {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  flex-shrink: 0;
}

.dashboard-progress-item.complete .dashboard-progress-marker {
  background-color: #4caf50;
  border-color: #4caf50;
  color: white;
}

.dashboard-progress-item.active .dashboard-progress-marker {
  border-color: #0e6bfc;
  animation: rotate 1.5s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dashboard-checkmark {
  margin: 0;
  padding: 0;
  font-size: 10px;
}

.dashboard-loading-icon {
  margin: 0;
  padding: 0;
  font-size: 10px;
  color: #0e6bfc;
}

.dashboard-progress-label {
  margin: 0;
  padding: 0;
  flex: 1;
}

.dashboard-progress-label h4 {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.dashboard-progress-subtitle {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #666;
}

/* Property matches styles */

.dashboard-property-matches {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dashboard-matches-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

.dashboard-property-match-item {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dashboard-match-image {
  width: 100%;
  height: 120px;
  object-fit: cover;
}

.dashboard-match-details {
  padding: 12px;
}

.dashboard-match-price {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}

.dashboard-match-specs,
.dashboard-match-address {
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: #666;
  margin-bottom: 2px;
}

.dashboard-view-all-btn {
  width: 100%;
  background: none;
  border: none;
  color: #0e6bfc;
  font-size: 14px;
  padding: 8px 0;
  cursor: pointer;
  text-align: center;
}
