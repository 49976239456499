/* ========== GENERAL INTRODUCTION COMPONENT STYLES ========== */
.introduction {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

/* ========== STEPS SECTION STYLES ========== */
.introduction__steps-list {
  margin: 0;
  padding-left: 20px;
}

.introduction__step-item {
  margin-bottom: 10px;
}

.introduction__step-note {
  color: #bac4ff;
}

/* ========== CHAT SUGGESTIONS STYLES ========== */
.introduction__chats {
  display: flex;
  gap: 8px;
  margin-top: 25px;
}

.introduction__chat,
.introduction__chat-suggestion {
  background-color: #f8f9ff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.introduction__chat {
  height: 30px;
  width: 30px;
  justify-content: center;
}

.introduction__chat-icon,
.introduction__chat-suggestion-icon {
  width: 15px;
  height: 15px;
}

.introduction__chat-suggestion {
  gap: 10px;
  width: auto;
  height: 30px;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 400;
}

/* ========== CTA BUTTON STYLES ========== */
.introduction__cta {
  margin-top: 65px;
}

.introduction__cta-button {
  width: 80px;
  background-color: var(--dashboard-primary-color);
  font-family: inherit;
  font-size: 14px;
  font-weight: 500;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 11px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.introduction__cta-button:hover {
  opacity: 0.8;
}
