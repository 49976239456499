.offer-negotiation {
    margin-top: 40px;
    color: var(--dashboard-primary-color);
    font-size: 17px;
    font-weight: 500;
    width: 100%;
  }
  
.offer-negotiation__header {
  display: flex;
  align-items: center;
  gap: 15px;
}
  
.offer-negotiation__avatar {
  width: 60px;
  height: auto;
}
  
.offer-negotiation__chat-icon-container {
  padding: 7px;
  border-radius: 10px;
  background-color: #f8f9ff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  cursor: pointer;
}
  
.offer-negotiation__chat-icon {
  width: 15px;
  height: auto;
}