/* ========== RECONNAISSANCE COMPONENT STYLES ========== */
.reconnaissance {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }
  
  .reconnaissance__left-side {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-right: 20px;
  }
  
  .reconnaissance__right-side {
    width: 50%;
  }
  
  /* ========== EXPLANATION TEXT STYLES ========== */
  .reconnaissance__explanation {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    color: var(--dashboard-primary-color);
  }
  
  .reconnaissance__text {
    font-size: 19px;
    line-height: 1.5;
  }
  
  /* ========== ACTION BUTTONS STYLES ========== */
  .reconnaissance__actions {
    margin-top: 20px;
  }
  
  .reconnaissance__send-button {
    width: 100px;
    background-color: var(--dashboard-primary-color);
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    color: white;
    border: none;
    border-radius: 10px;
    padding: 12px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 15px;
  }
  
  .reconnaissance__send-button:hover {
    opacity: 0.8;
  }
  
  .reconnaissance__skip-text {
    font-size: 14px;
    color: #666;
    cursor: pointer;
    text-decoration: underline;
  }
 