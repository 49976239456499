.login_container {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px);
  flex-direction: column;
}

.login_formWrapper {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.login_heading {
  font-family: "Outfit", sans-serif;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
}

.login_subtitle {
  font-family: "Outfit", sans-serif;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 40px;
}

.login_inputGroup {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.login_inputField {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px 15px;
  width: 100%;
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.login_inputField input.PhoneInputInput {
  border: None;
}

.login_inputField input.PhoneInputInput:focus {
  outline: none;
}

.login_inputLabel {
  font-family: "Outfit", sans-serif;
  font-size: 13px;
  text-align: left;
}

.login_button {
  background-color: #3654ff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 12px 0;
  width: 100%;
  cursor: pointer;
  font-family: "Outfit", sans-serif;
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
}

.login_button:disabled {
  background-color: grey;
  color: rgb(206, 206, 206);
}

.login_alert {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  min-height: 20px; /* Maintain consistent height */
  visibility: hidden; /* Default to hidden */
}

.login_alert--visible {
  visibility: visible; /* Show only when there's an error */
}

.login_forgot_link {
  font-family: "Outfit", sans-serif;
  font-size: 14px;
}

.login_signup_link {
  font-family: "Outfit", sans-serif;
  font-size: 14px;
  text-align: center;
}

.login_home_icon {
  width: 45px;
  height: 45px;
}

.login_spinner {
  width: 30px;
  height: 30px;
  border: 4px solid rgba(255, 255, 255, 0.3); /* light color */
  border-top: 4px solid #3654ff; /* Blue color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
